<template>
    <div class="template-page news">
        <basic-page :pagedata="this.pageData" :bannerimg="this.bannerimg">

            <h3>Filter by</h3>
            <div class="filtri">
                <div class="tags-filter">
                    <button type="checkbox" 
                        :class="{'image-tag': true, 'active': checkActive(t)}" 
                        v-for="(t, index) in this.tagsArray" :key="'tag'+index" :value="t" name="filtertag"
                        @click="addFilter">{{ t }}</button>
                </div>

                <div class="ricerca-testo">
                    <input v-model="research" placeholder="Search the content...">
                </div>
            </div>

            <transition-group name="list" tag="div">
                <div v-for="(n, index) in this.loadedNews" :key="'news'+index" >
                    <single-news :data="n"></single-news>
                </div>
            </transition-group>

            <br>
            <div class="loadmore" v-if="this.newsData.length > this.loaded">
                <button class="cta" @click="loadMore()"><span>Load More</span></button>
            </div>
        </basic-page>
    </div>
</template>

<script>
import SingleNews from '../components/SingleNews'
import BasicPage from '../views/BasicPage'
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
export default {
    name: 'news',
    components: {
        SingleNews,
        BasicPage
    },
    data: () => {
        return {
            newsData: [],
            pageData: {},
            currentPageId: "bd4230a3-bd52-424f-ba37-aef12581efc5",
            loaded: 5,
            tagsArray: [],
            filteringTags: [],
            research: '',
            bannerimg: null
        }
    },
    methods: {
        loadMore() {
            this.loaded += 5;
        },
        addFilter(event) {
            if(this.filteringTags.includes(event.target.value))
                this.filteringTags.splice(this.filteringTags.indexOf(event.target.value), 1)
            else
                this.filteringTags.push(event.target.value)
            //console.log(this.filteringTags)
        },
        checkActive(t) {
            return this.filteringTags.includes(t)
        },
    },
    computed: {
        loadedNews() {
            var news = []
            if(this.filteringTags.length == 0)
                news = this.newsData.slice(0, this.loaded)
            else
                news = this.newsData
                    .filter(n => {
                        var r = false;
                        n.field_news_sector.forEach(s => {
                            if(this.filteringTags.includes(s.attributes.name))
                                r = true
                        })
                        return r
                    })
                    .slice(0, this.loaded)
            
            return news.filter(n => {
                return (
                    n.attributes.title.toLowerCase().includes(this.research.toLowerCase())
                    || n.attributes.field_news_content.processed.toLowerCase().includes(this.research.toLowerCase())
                )
            })
        }
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top'] })
            .then(res => {
                this.pageData = res[0].attributes
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })


            
        fetchNodes('news', {
            include: ['field_news_sector', 'field_news_image', 'field_news_attachment']
        }).then(res => {

            this.newsData = res.filter((n)=>{
            //console.log('n.attributes.field_is_opportunity_news',n.attributes.field_is_opportunity_news);
                if(n.attributes.field_is_opportunity_news == false){
                    return n
                }
            })

            this.newsData = this.newsData.sort((a, b) => {
                return a.attributes.field_news_data > b.attributes.field_news_data ? -1 : 1
            })

            this.newsData.forEach(n => {
                n.field_news_sector.forEach(t => {
                    if(!this.tagsArray.includes(t.attributes.name))
                        this.tagsArray.push(t.attributes.name)
                })
            })

            //console.log(this.tagsArray)
        })
    },
    watch: {
        research() {
            //console.log(this.research)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>